import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/data/collect",
    name: "CollectData",
    component: () => import("@/views/collect.vue"),
    meta: {
      title: "学员信息收集",
    }
  },
  {
    path: "/student-data/collect",
    name: "CollectStudentData",
    component: () => import("@/views/collect-student.vue"),
    meta: {
      title: "新生入学登记",
    }
  },
  {
    path: "/student-data/account",
    name: "StudentDataAccount",
    component: () => import("@/views/student-account.vue"),
    meta: {
      title: "珊瑚课程账号",
    }
  },
  {
    path: "/clue-data/collect",
    name: "CollectClueData",
    component: () => import("@/views/collect-clue.vue"),
    meta: {
      title: "领取资料信息登记",
    }
  },
  {
    path: '/exam/:codeInfo?',
    name: 'CollectStudentTest',
    component: () => import("@/views/collect-student-test.vue"),
    meta: {
      title: '学生作业'
    }
  },
  {
    path: '/exam/result/:codeInfo?',
    name: 'CollectStudentTest',
    component: () => import("@/views/collect-student-result.vue"),
    meta: {
      title: '学生作业解析'
    }
  },
  {
    path: "/information/detail/:id?",
    name: "InformationDetail",
    component: () => import("@/views/information-detail.vue"),
    meta: {
      title: "信息详情",
    }
  },
  {
    path: "/:id?",
    name: "Evaluation",
    component: () => import("@/views/answer.vue"),
    meta: {
      title: "班主任调查问卷",
    }
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
